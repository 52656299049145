var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "group_aside_template" }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        0
          ? _c("sidebar-search-group", {
              ref: "sidebar_search_group",
              staticClass: "sidebar_search_group",
            })
          : _vm._e(),
        _vm._l(_vm.menu, function (item, index) {
          return _c("div", { key: index, staticClass: "menu_group" }, [
            _c(
              "div",
              {
                staticClass: "menu_item",
                on: {
                  click: function ($event) {
                    return _vm.goToMenu(item)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "menu_unit_group",
                    class: {
                      on: item.name == _vm.menu_index && !item.children,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.menuHandler(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "menu_unit cp" }, [
                      _c("div", { staticClass: "name_group" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: {
                            src: require(`@/assets/images/fun_template/${item.name}.png`),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "name un_sel" }, [
                          _vm._v(_vm._s(item.desc)),
                        ]),
                      ]),
                      item.children
                        ? _c("div", {
                            staticClass: "arrow",
                            class: { on: _vm.menu_index == item.name },
                          })
                        : _vm._e(),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          (item.name == _vm.menu_index || item.children) &&
                          !item.close &&
                          item.children,
                        expression:
                          "(item.name == menu_index || item.children) && !item.close && item.children",
                      },
                    ],
                    staticClass: "sub_menu_group",
                    class: {
                      on:
                        (item.name == _vm.menu_index || item.children) &&
                        !item.close,
                    },
                  },
                  [
                    _vm._l(item.children, function (child_item, child_index) {
                      return [
                        (child_item.name == "sell" &&
                          _vm.$tools.getCookie("user").sign) ||
                        child_item.name != "sell"
                          ? _c(
                              "div",
                              {
                                key: `child${child_index}`,
                                staticClass: "sub_menu_item cp un_sel",
                                class: {
                                  on:
                                    _vm.sub_menu_index == child_item.name &&
                                    _vm.menu_index == item.name,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.sub_menu_index = child_item.name
                                    _vm.goToSubMenu(item, child_item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(child_item.desc))]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }